import { BrowserHttpClient, type HttpClient } from '@transferwise/service-comms';

export function createHttpClient(): HttpClient {
  return new BrowserHttpClient({
    axiosConfig: {
      headers: {
        'X-Access-Token': 'Tr4n5f3rw153',
      },
    },
  });
}
