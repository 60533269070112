import { HttpClient } from '@transferwise/service-comms';

import { FuseResults } from '../../types/search';

const searchEndpoint = (query: string) =>
  `${process.env.NEXT_PUBLIC_LOCAL_API_URL || ''}/search-api?searchQuery=${query}`;

interface SearchResults {
  data: FuseResults[];
}

export const getSearchResults = async (
  httpClient: HttpClient,
  query: string,
): Promise<FuseResults[]> => {
  const res = await httpClient.get<SearchResults>({
    url: searchEndpoint(query),
    upstream: 'search-api',
  });
  return res.data.data;
};
